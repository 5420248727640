import React from 'react';
import classnames from 'classnames';
import { graphql, navigate } from 'gatsby';

import './SignCorporation.scss';

import BackButton from '../BackButton/BackButton';
import JumpToLinks from '../JumpToLinks/JumpToLinks';
import CountrySelect from '../FormControls/CountrySelect';

import { sanitizer, useCSSMotion } from '../../util';

function JumpToLinksWrapper({ hashLinks, containerRef }) {
  const motionState = useCSSMotion(containerRef);
  return <JumpToLinks hashLinks={hashLinks} motionState={motionState} />;
}

class SignCorporation extends React.Component {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef(null);

    this.state = {
      signNow: null,
      isProcessing: false,
      docusign_signing_url: null,
      error: null,
      fieldErrors: new Set(),
    };
  }

  hasFieldError(id) {
    return this.state.fieldErrors.has(id);
  }

  signNow(data) {
    this.setState({ signNow: true }, () => {
      this.submitForm(data);
    });
  }

  signLater(data) {
    this.setState({ signNow: false }, () => {
      this.submitForm(data);
    });
  }

  submitForm(data) {
    const { agreement, signature } = data;
    const fieldErrors = new Set();

    this.setState(
      {
        error: null,
        fieldErrors,
        isProcessing: true,
      },
      () => {
        const requiredFieldIds = [
          'corporation_name',
          'corporation_address',
          'corporation_country_code',
          'primarycontact_name',
          'primarycontact_title',
          'primarycontact_email',
          'primarycontact_phone',
          'signer_name',
          'signer_title',
          'signer_email',
          'signer_confirm_email',
        ];

        requiredFieldIds.forEach((id) => {
          const value = getFormValue(id);
          if (isEmptyValue(value)) {
            fieldErrors.add(id);
          }
        });

        if (fieldErrors.size > 0) {
          this.setState({
            fieldErrors,
            error: new Error('Please fill in all required fields.'),
            isProcessing: false,
          });
          return;
        }

        const formData = {
          cla_type: 'corporate',
          cla_version: agreement.version,
          name: getFormValue('corporation_name'),
          address: getFormValue('corporation_address'),
          country_code: getFormValue('corporation_country_code'),
          primarycontact_name: getFormValue('primarycontact_name'),
          primarycontact_title: getFormValue('primarycontact_title'),
          primarycontact_email: getFormValue('primarycontact_email'),
          primarycontact_phone: getFormValue('primarycontact_phone'),
          primarycontact_fax: getFormValue('primarycontact_fax'),
          signer_name: getFormValue('signer_name'),
          signer_title: getFormValue('signer_title'),
          signer_email: getFormValue('signer_email'),
          signer_confirm_email: getFormValue('signer_confirm_email'),
          sign_now: this.state.signNow,
          site_url: process.env.SITE_URL,
          lang: window.general.lang,
        };

        const options = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(formData),
        };

        fetch(process.env.WP_BACKEND_URL + process.env.LOCALE + '/wp-json/jfl/v1/cla/sign', options)
          .then((res) => res.json())
          .then((res) => {
            if (isResponseError(res)) {
              const responseFieldErrors = parseResponseFieldErrors(res);
              this.setState(
                {
                  error: parseResponseError(res),
                  fieldErrors: responseFieldErrors,
                  isProcessing: false,
                },
                () => {
                  if (responseFieldErrors.size > 0) {
                    focusFormField(responseFieldErrors.values().next().value);
                  }
                },
              );
              return;
            }
            if (this.state.signNow && res.docusign_signing_url) {
              window.location.replace(res.docusign_signing_url);
            } else {
              navigate(signature.completePage, {
                replace: true,
              });
            }
          })
          .catch((error) => {
            this.setState({
              error: error,
              isProcessing: false,
            });
          });
      },
    );
  }

  renderSignForm(data) {
    const { signature } = data;
    const { isProcessing, signNow } = this.state;

    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <h4 className="sub-title">{signature.corporationTitle}</h4>
        <div className="field-group">
          <div className={classnames('field', { error: this.hasFieldError('corporation_name') })}>
            <label htmlFor="corporation_name">{signature.nameLabel + '*'}</label>
            <input type="text" id="corporation_name" />
          </div>
        </div>
        <div className="field-group">
          <div className={classnames('field', { error: this.hasFieldError('corporation_address') })}>
            <label htmlFor="corporation_address">{signature.addressLabel + '*'}</label>
            <input type="text" id="corporation_address" />
          </div>
          <div className={classnames('field', { error: this.hasFieldError('corporation_country_code') })}>
            <label htmlFor="corporation_country_code">{signature.countryLabel + '*'}</label>
            <div className="select-wrapper">
              <CountrySelect id="corporation_country_code" />
            </div>
          </div>
        </div>
        <h4 className="sub-title has-description">{signature.primarycontactTitle}</h4>
        <p className="description">{signature.primarycontactDescription}</p>
        <div className="field-group">
          <div className={classnames('field', { error: this.hasFieldError('primarycontact_name') })}>
            <label htmlFor="primarycontact_name">{signature.primarycontactNameLabel + '*'}</label>
            <input type="text" id="primarycontact_name" />
          </div>
          <div className={classnames('field', { error: this.hasFieldError('primarycontact_title') })}>
            <label htmlFor="primarycontact_title">{signature.primarycontactTitleLabel + '*'}</label>
            <input type="text" id="primarycontact_title" />
          </div>
        </div>
        <div className="field-group">
          <div className={classnames('field', { error: this.hasFieldError('primarycontact_email') })}>
            <label htmlFor="primarycontact_email">{signature.primarycontactEmailLabel + '*'}</label>
            <input type="text" id="primarycontact_email" />
          </div>
        </div>
        <div className="field-group">
          <div className={classnames('field', { error: this.hasFieldError('primarycontact_phone') })}>
            <label htmlFor="primarycontact_phone">{signature.primarycontactPhoneLabel + '*'}</label>
            <input type="text" id="primarycontact_phone" />
          </div>
          <div className={classnames('field', { error: this.hasFieldError('primarycontact_fax') })}>
            <label htmlFor="primarycontact_fax">{signature.primarycontactFaxLabel}</label>
            <input type="text" id="primarycontact_fax" />
          </div>
        </div>
        <h4 className="sub-title has-description">{signature.signerTitle}</h4>
        <p className="description">{signature.signerDescription}</p>
        <div className="field-group">
          <div className={classnames('field', { error: this.hasFieldError('signer_name') })}>
            <label htmlFor="signer_name">{signature.signerNameLabel + '*'}</label>
            <input type="text" id="signer_name" />
          </div>
          <div className={classnames('field', { error: this.hasFieldError('signer_title') })}>
            <label htmlFor="signer_title">{signature.signerTitleLabel + '*'}</label>
            <input type="text" id="signer_title" />
          </div>
        </div>
        <div className="field-group">
          <div className={classnames('field', { error: this.hasFieldError('signer_email') })}>
            <label htmlFor="signer_email">{signature.signerEmailLabel + '*'}</label>
            <input type="text" id="signer_email" />
          </div>
          <div className={classnames('field', { error: this.hasFieldError('signer_confirm_email') })}>
            <label htmlFor="signer_confirm_email">{signature.signerConfirmEmailLabel + '*'}</label>
            <input type="text" id="signer_confirm_email" />
          </div>
        </div>
        <div>
          {/* TODO: Get button text dynamically */}
          <button
            className={classnames('submit-button', { processing: isProcessing })}
            disabled={isProcessing}
            onClick={() => this.signNow(data)}
          >
            {isProcessing && signNow ? 'Processing...' : signature.submitText || 'Submit'}
          </button>
          {/* TODO: Get button text dynamically */}
          <button
            className={classnames('submit-button', { processing: isProcessing })}
            disabled={isProcessing}
            onClick={() => this.signLater(data)}
          >
            {/* TODO: signature.signLaterText not available in WP dashboard, will always default to Sign Later */}
            {isProcessing && signNow === false ? 'Processing...' : signature.signLaterText || 'Sign Later'}
          </button>
        </div>
        {this.renderFormError()}
      </form>
    );
  }

  // TODO: this is not used, why?
  renderSignComplete(signature) {
    return (
      <div>
        <h4 className="sub-title">{signature.title}</h4>
        {/* TODO completeMessage is missing? */}
        <div className="sign-complete">{signature.completeMessage}</div>
      </div>
    );
  }

  renderFormError() {
    const { error } = this.state;

    if (error) {
      return (
        <div className="form-error">
          <div className="label">Error</div>
          <div className="message">{error.message || error}</div>
        </div>
      );
    }

    return null;
  }

  render() {
    const { data } = this.props;
    const { title, agreement, signature } = data;

    if (!data) {
      return null;
    }

    return (
      <section className="SignCorporationCLA" ref={this.containerRef}>
        <div className="breadcrumbs">
          {/* TODO: needs to be dynamic and translated */}
          <BackButton ref={this.containerRef} url="/cla-sign/" title="Back to Contributor License Agreements" />
        </div>

        <div className="container">
          <JumpToLinksWrapper
            containerRef={this.containerRef}
            hashLinks={[
              {
                id: 'agreement',
                jumpToLinkTitle: agreement.title,
              },
              {
                id: 'sign',
                jumpToLinkTitle: signature.title,
              },
            ]}
          />

          <div className="content">
            <h1 className="title">{title}</h1>

            <div id="agreement">
              <h4 className="sub-title">{agreement.title}</h4>
              <div dangerouslySetInnerHTML={{ __html: sanitizer(agreement.content) }} />
            </div>

            <div id="sign">{this.renderSignForm(data)}</div>
          </div>
        </div>
      </section>
    );
  }
}

function getFormValue(id) {
  const element = document.getElementById(id);
  if (element) {
    return element.value;
  }

  return null;
}

function isEmptyValue(value) {
  return !value || value.length === 0;
}

function isResponseError(res) {
  return res.code !== 'success';
}

function parseResponseError(res) {
  if (res.message) {
    return new Error(res.message);
  } else if (res.code) {
    return new Error(`An error occurred: ${res.code}`);
  } else {
    return new Error('An unknown error occurred.');
  }
}

function parseResponseFieldErrors(res) {
  if (res.code) {
    return new Set([res.code]);
  } else {
    return new Set();
  }
}

function focusFormField(id) {
  const element = document.getElementById(id);
  if (element) {
    return element.focus();
  }
}

export default SignCorporation;

export const fragments = graphql`
  fragment ClaSignCorporation on WpPage_Flexiblelayout_FlexibleChildren_ClaSignCorporation {
    agreement {
      content
      title
      version
    }
    signature {
      addressLabel
      completePage
      corporationTitle
      countryLabel
      nameLabel
      primarycontactDescription
      primarycontactEmailLabel
      primarycontactFaxLabel
      primarycontactNameLabel
      primarycontactPhoneLabel
      primarycontactTitle
      primarycontactTitleLabel
      signerConfirmEmailLabel
      signerDescription
      signerEmailLabel
      signerNameLabel
      signerTitle
      signerTitleLabel
      submitText
      title
    }
    title
  }

  fragment ClaSignCorporation_Section on WpPage_Flexiblelayout_FlexibleChildren_Section_FlexibleChildren_ClaSignCorporation {
    agreement {
      content
      title
      version
    }
    signature {
      addressLabel
      completePage
      corporationTitle
      countryLabel
      nameLabel
      primarycontactDescription
      primarycontactEmailLabel
      primarycontactFaxLabel
      primarycontactNameLabel
      primarycontactPhoneLabel
      primarycontactTitle
      primarycontactTitleLabel
      signerConfirmEmailLabel
      signerDescription
      signerEmailLabel
      signerNameLabel
      signerTitle
      signerTitleLabel
      submitText
      title
    }
    title
  }
`;
